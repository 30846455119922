$button-text-hover-background-color: transparent;

@import '~bulma';

.site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.site-content {
  flex: 1;
}

.header {
  padding: 20px 0 0 20px;
}

.clickable {
  cursor: pointer;
}

.icon-nav:hover {
  color: #99ddff;   
}

.index {
  padding: 15px !important;
}

.index-nav {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 6px;
}

.tabular {
  font-size: .9rem !important;
}
